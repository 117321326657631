<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="ctft">
        <heade></heade>
        <div class="con">
            <div class="box">
                <img src="../../assets/duigou.png" alt="">
                <p class="p1">您的认证已经提交成功，我们正在加速审核中</p>
                <p class="p2">审核结果将以短信形式发送到您的手机上，请注意查收。</p>
                <div>
                    <p class="p3" @click="fan">返回</p>
                    <p class="p4" @click="jix">继续添加</p>
                </div>
            </div>
        </div>
        <foote></foote>
    </div>
</template>

<script>
import heade from '../../components/Heade'
import foote from '../../components/Foote'
export default {
  name: 'ctft',

  data () {
    return {
    }
  },
  components: { heade, foote },
  methods: {
    fan () {
      this.$router.push('/install')
    },
    jix () {
      this.$router.push('/certification')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ctft {
    background:#E7E8EB;
    height: 100vh;
    .con{
        width: 100%;
        height:85.2%;
        background:#E7E8EB;
        display: flex;
        align-items: center;
        .box{
            height:5.1rem;
            width:9.59rem;
            margin:0 auto;
            background: #fff;
            img{
                height: 0.8rem;
                width: 0.8rem;
                display: block;
                margin:0 auto;
                margin-top:0.81rem;
                margin-bottom: 0.4rem;
            }
            .p1{
                font-size: 0.24rem;
                color:#1A2533;
                font-weight: 500;
                text-align: center;
                margin-bottom: 0.14rem;
            }
            .p2{
                font-size: 0.14rem;
                color:#888888;
                text-align: center;
            }
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top:0.93rem;
                p{
                    height:0.45rem;
                    width:1.79rem;
                    border-radius: 0.06rem;
                    color:#fff;
                    margin: 0 0.3rem;
                    font-size: 0.22rem;
                    line-height: 0.45rem;
                    text-align: center;
                }
                .p3{
                    background: #B6C1CF;
                }
                .p4{
                    background: #F96C6C;
                }
            }
        }
    }
}
</style>
